import '../styles/index.scss';
import {preloadImages} from "./utils";
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {Observer} from "gsap/Observer";

gsap.registerPlugin(SplitText, Observer);

class Slider {
	constructor(el) {
		this.DOM = {el: el ? el : document.body};
		this.DOM.smallSlides = Array.from(this.DOM.el.querySelectorAll('.small-slide'));
		this.DOM.bigSlides = Array.from(this.DOM.el.querySelectorAll('.big-slide'));
		this.DOM.texts = Array.from(this.DOM.el.querySelectorAll('.text-container .text'));
		this.DOM.textsSpans = Array.from(this.DOM.el.querySelectorAll('.text-container .text span'));
		this.DOM.counters = Array.from(this.DOM.el.querySelectorAll('.compteur[data-index]'));
		this.slidesTotal = this.DOM.smallSlides.length - 1;
		this.current = 0;
		this.previous = this.slidesTotal;
		this.next = this.current + 1;
		this.splitText = new SplitText(this.DOM.textsSpans, {type: 'chars'});
		this.inited = false;
		this.transitioning = false;
		this.init();
	}

	init() {
		this.DOM.smallSlides[this.current].classList.add('current');
		this.DOM.bigSlides[this.current].classList.add('current');
		this.DOM.texts[this.current].classList.add('current');
		this.DOM.smallSlides[this.next].classList.add('next');
		this.DOM.bigSlides[this.next].classList.add('next');
		this.DOM.smallSlides[this.previous].classList.add('previous');
		this.DOM.bigSlides[this.previous].classList.add('previous');

		this.handleEvents();

		this.setSmallCurrent('next')
		this.setCounter('next')
		this.setBigCurrent('next')
		this.setCurrentText('next')

		this.inited = true;
	}


	handleEvents() {
		document.querySelectorAll('.arrows .arrow').forEach(arrow => {
			arrow.addEventListener('click', (e) => {
				if (e.currentTarget.classList.contains('arrow-top')) {
					this.nextSlide();
				} else {
					this.prevSlide();
				}
			})
		})

		Observer.create({
			target: window,         // can be any element (selector text is fine)
			type: "touch, pointer, scroll",    // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
			onUp: () => this.nextSlide(),
			onDown: () => this.prevSlide(),
		});
	}

	setPrevious(index) {
		this.DOM.smallSlides[index].classList.add('previous');
		this.DOM.bigSlides[index].classList.add('previous');
	}

	setNext(index) {
		this.DOM.smallSlides[index].classList.add('next');
		this.DOM.bigSlides[index].classList.add('next');
	}

	removePrevious(index) {
		this.DOM.smallSlides[index].classList.remove('previous');
		this.DOM.bigSlides[index].classList.remove('previous');
	}

	removeNext(index) {
		this.DOM.smallSlides[index].classList.remove('next');
		this.DOM.bigSlides[index].classList.remove('next');
	}

	setCurrent(index) {
		let direction = this.current < index ? 'next' : 'prev';

		this.previous = this.current;
		this.current = index;
		this.next = this.current === this.slidesTotal ? 0 : this.current + 1;
	}

	nextSlide() {
		if (this.transitioning) {
			return;
		} else {
			this.transitioning = true;
		}
		this.setCurrent(this.current >= this.slidesTotal ? 0 : this.current + 1)
		this.setSmallCurrent('next')
		this.setBigCurrent('next')
		this.setCurrentText('next')
		this.setCounter('next')
	}

	prevSlide() {
		if (this.transitioning) {
			return;
		} else {
			this.transitioning = true;
		}
		this.setCurrent(this.current <= 0 ? this.slidesTotal : this.current - 1)
		this.setSmallCurrent('prev')
		this.setBigCurrent('prev')
		this.setCurrentText('prev')
		this.setCounter('prev')
	}


	setSmallCurrent(direction) {
		this.DOM.smallSlides[this.current].classList.add('current');
		const tl = gsap.timeline();

		//create gsap label start
		tl.addLabel('start', 0);

		gsap.set(this.DOM.smallSlides[this.current], {
			clipPath: direction === 'next' ? 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)' : 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
			zIndex: 2,
			opacity: 1,
		})

		if (this.inited) {
			tl.to(this.DOM.smallSlides[direction === 'next' ? this.previous : this.next], {
				clipPath: direction === 'next' ? 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)' : 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
				zIndex: 1,
				duration: 1,
				ease: 'power3.inOut',
			}, 'start')
		}

		tl.to(this.DOM.smallSlides[this.current], {
			duration: 1,
			ease: 'power3.inOut',
			clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
		}, 'start')

	}

	setCurrentText(direction) {
		this.DOM.texts[this.current].classList.add('current');
		const tl = gsap.timeline();
		tl.addLabel('start', 0)
		gsap.set(this.DOM.texts[this.current].querySelectorAll('span > div'), {
			opacity: 0,
			y: direction === 'next' ? '-100%' : '100%',
		})

		if (this.inited) {
			tl.to(this.DOM.texts[direction === 'next' ? this.previous : this.next].querySelectorAll('span > div'), {
				opacity: 0,
				duration: 1,
				y: direction === 'next' ? '100%' : '-100%',
				ease: 'power3.inOut',
				stagger: 0.05,
			}, 'start')
		}

		gsap.set(this.DOM.texts[this.current], {
			opacity: 1,
		})

		tl.to(this.DOM.texts[this.current].querySelectorAll('span > div'), {
			duration: 1,
			opacity: 1,
			y: '0%',
			ease: 'power3.inOut',
			stagger: 0.05,
		}, 'start')
	}

	setCounter(direction) {
		console.log(this.DOM.counters);
		console.log(this.current)
		this.DOM.counters[this.current].classList.add('current');
		const tl = gsap.timeline();

		tl.addLabel('start', 0)

		gsap.set(this.DOM.counters[this.current], {
			y: direction === 'next' ? '-100%' : '100%',
		})

		if (this.inited) {
			console.log(this.DOM.counters);
			console.log(this.previous)
			console.log(this.next)
			tl.to(this.DOM.counters[direction === 'next' ? this.previous : this.next], {
				opacity: 0,
				duration: 1,
				y: direction === 'next' ? '100%' : '-100%',
				ease: 'power3.inOut',
			}, 'start')
		}

		tl.to(this.DOM.counters[this.current], {
			duration: 1,
			opacity: 1,
			y: '0%',
			ease: 'power3.inOut',
		}, 'start')
	}

	setBigCurrent(direction) {
		this.DOM.bigSlides[this.current].classList.add('current');
		const tl = gsap.timeline({
			onComplete: () => {
				this.transitioning = false;
			}
		});
		tl.addLabel('start', 0);
		gsap.set(this.DOM.bigSlides[this.current], {
			clipPath: direction === 'next' ? 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)' : 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)',
			x: direction === 'next' ? '-50%' : '50%',
			zIndex: 2,
			opacity: 1,
		}, 'start')

		gsap.set(this.DOM.bigSlides[direction === 'next' ? this.previous : this.next], {
			zIndex: 1,
		}, 'start')

		if (this.inited) {
			tl.to(this.DOM.bigSlides[direction === 'next' ? this.previous : this.next], {
				clipPath: direction === 'next' ? 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)' : 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)',
				duration: 1,
				ease: 'power3.inOut',
			}, 'start')
		}

		tl.to(this.DOM.bigSlides[this.current], {
			duration: 1,
			clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
			x: '0%',
			ease: 'power3.inOut',
		}, 'start')

	}
}

// Preload images, initialize smooth scrolling, apply scroll-triggered animations, and remove loading class from body
preloadImages('img').then(() => {
	new Slider();
	document.body.classList.remove('loading');
});
